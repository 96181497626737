 /* SearchBox.css */

 .searchBody {
    width: 100%  ;
     height: 200px;
     /* background-color: #15939a; */
     /* background-image: url("https://media.licdn.com/dms/image/C4E0BAQEWmLbx4LlRHA/company-logo_200_200/0/1596941842942?e=2147483647&v=beta&t=U8ts_81bWWo_G5-jzlYTrhMqnwJUJv6vrBPi2LKAWqI"); */
     display: flex;
     align-items: center;
     justify-content: center;
     background-size: cover;
     background-repeat: no-repeat;
     background-color: #c8d8e4;
    
 }

 .searchBox {
     display: flex;
     align-items: center;
     background-color: #fff;
     width: 100%;
     margin: 0 auto;
     padding: 7px;
     /* border: 1px solid #fff; */
     border-radius: 30px;


 }

 .searchBoxClick {
     display: flex;
     align-items: center;
     background-color: #fff;
     width: 100%;
     margin: 0 auto;
     padding: 7px ;
     /* border: 1px solid #fff; */
     border-top-left-radius: 30px;
     border-top-right-radius: 30px;


 }

 .categoryList {
     /* display: flex; */
     position: absolute;
     align-items: center;
     background-color: #fff;
     width: 97%;
     margin: 0 auto;
     padding: 0px 0px 10px 10px;
     /* border: 1px solid #fff; */
     /* border-radius: 40px; */
     border-bottom-left-radius: 30px;
     border-bottom-right-radius: 30px;
 }

 .searchBox input {
     flex: 1;
     padding: 8px;
     border: none;
     outline: none;
     font-size: 16px;
 }

 .searchBox button {
     padding: 8px 12px;
     margin-left: 10px;
     border: none;
     border-radius: 5px;
     background-color: #007bff;
     color: #fff;
     font-size: 16px;
     cursor: pointer;
 }

 .searchBoxClick input {
     flex: 1;
     padding: 8px;
     border: none;
     outline: none;
     font-size: 16px;
 }

 .searchBoxClick button {
     padding: 8px 12px;
     margin-left: 10px;
     border: none;
     border-radius: 5px;
     background-color: #007bff;
     color: #fff;
     font-size: 16px;
     cursor: pointer;
 }

 .searchBox button:hover {
     background-color: #0056b3;
 }


 @media only screen and (max-width: 768px) {
     .searchBox {
         display: flex;
         align-items: center;
         background-color: #fff;
         width: 100%;
         margin: 0 auto;
         padding: 7px;
         /* border: 1px solid #fff; */
         border-radius: 30px;


     }

     .searchBoxClick {
         display: flex;
         align-items: center;
         background-color: #fff;
         width: 100%;
         margin: 0 auto;
         padding:  7px;
         /* border: 1px solid #fff; */
         border-top-left-radius: 30px;
         border-top-right-radius: 30px;


     }

     .categoryList {
         /* display: flex; */
         align-items: center;
         background-color: #fff;
         width: 91%;
         margin: 0 auto;
         padding:  7px;
         /* border: 1px solid #fff; */
         /* border-radius: 40px; */
         border-bottom-left-radius: 30px;
         border-bottom-right-radius: 30px;
     }
 }